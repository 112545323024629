import de from '../../lang/de.json';
import en from '../../lang/en.json';
import fr from '../../lang/fr.json';
import nl from '../../lang/nl.json';

const messages = {
  nl,
  fr,
  de,
  en,
};

export default messages;
