// Vendor
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { createInertiaApp, usePage } from '@inertiajs/vue3';
import * as Sentry from '@sentry/vue';
import axios from 'axios';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { DefineComponent, createSSRApp, h, watch } from 'vue';
import { createI18n } from 'vue-i18n';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/index';

// Application
import './fontawesome';
import messages from './locales';

// Setup the application.
axios.defaults.withCredentials = true;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Nordic Fire';

createInertiaApp({
  progress: {
    color: '#4B5563',
  },
  title: (title) => `${title} | ${appName}`,
  resolve: (name) => resolvePageComponent(`./pages/${name}.vue`, import.meta.glob<DefineComponent>('./pages/**/*.vue')),
  setup({ el, App, props, plugin }) {
    // Setup internalization.
    const i18n = createI18n({
      legacy: false,
      locale: props.initialPage?.props?.locale || 'nl',
      fallbackLocale: 'nl',
      messages,
    });

    const app = createSSRApp({ render: () => h(App, props) });

    // Watch for user changes for Sentry.
    const page = usePage();
    watch(
      () => page.props,
      () =>
        page.props.auth?.user
          ? Sentry.setUser({
              id: page.props.auth.user.id,
              username: page.props.auth.user.email,
              email: page.props.auth.user.email,
            })
          : Sentry.setUser(null),
    );

    // Setup Sentry.
    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration({ maskAllText: false, blockAllMedia: false })],
      tracesSampleRate: 0.025,
      replaysSessionSampleRate: 0.05,
      replaysOnErrorSampleRate: 1.0,
    });

    app.use(plugin).use(i18n).use(ZiggyVue, Ziggy).component('font-awesome-icon', FontAwesomeIcon).mount(el);
  },
});
