import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAngleRight,
  faArrowRight,
  faArrowRightArrowLeft,
  faArrowRightFromBracket,
  faArrowUp,
  faBars,
  faBasketShopping,
  faBell,
  faBookOpen,
  faBox,
  faCalendar,
  faCalendarWeek,
  faCartShopping,
  faChartLine,
  faCheck,
  faClipboardList,
  faCog,
  faDesktop,
  faDownload,
  faEllipsisStrokeVertical,
  faEuro,
  faEye,
  faEyeSlash,
  faFile,
  faFileInvoiceDollar,
  faFireplace,
  faFolder,
  faGrid2,
  faKey,
  faList,
  faMagnifyingGlass,
  faNfcMagnifyingGlass,
  faPaperclip,
  faPen,
  faPhone,
  faPlus,
  faPrint,
  faScrewdriverWrench,
  faSlash,
  faSort,
  faStore,
  faTrash,
  faTruck,
  faUser,
  faUserGear,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';

import { faCheckCircle, faCircleInfo, faXmarkCircle } from '@fortawesome/pro-solid-svg-icons';

library.add(
  // Regular
  faAngleRight,
  faArrowRight,
  faArrowUp,
  faArrowRightArrowLeft,
  faBars,
  faBell,
  faBookOpen,
  faDesktop,
  faDownload,
  faEllipsisStrokeVertical,
  faFile,
  faFolder,
  faKey,
  faStore,
  faUser,
  faUserGear,
  faPen,
  faPhone,
  faPlus,
  faTrash,
  faXmark,
  faMagnifyingGlass,
  faClipboardList,
  faFileInvoiceDollar,
  faTruck,
  faCalendarWeek,
  faCheck,
  faCartShopping,
  faBasketShopping,
  faScrewdriverWrench,
  faEuro,
  faSlash,
  faCalendar,
  faChartLine,
  faNfcMagnifyingGlass,
  faArrowRightFromBracket,
  faFireplace,
  faPaperclip,
  faCog,
  faPrint,
  faEye,
  faEyeSlash,
  faList,
  faGrid2,
  faSort,
  faBox,

  // Solid
  faXmarkCircle,
  faCheckCircle,
  faCircleInfo,
);
